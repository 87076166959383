import * as React from 'react'
import styled from 'styled-components'
import formatPrice from 'utils/formatPrice'
import { FiExternalLink } from 'react-icons/fi'
import { FaRegHeart } from 'react-icons/fa'
import config from 'config'
import { ms } from 'modules/browser/const'
import useProductImage from './hooks/useProductImage'
import useGrouped from './hooks/useGrouped'
import * as actions from './actions'
import { useWishlistListingItem } from 'modules/wishlist'
import * as t from './types'
import { useInView } from 'react-intersection-observer'
import Button from 'theme/molecules/Button'
import { FaTrash } from 'react-icons/fa'
import AdminInfo from 'theme/atoms/AdminInfo'
import { adminMode } from 'config/utils'
import Link from 'theme/atoms/Link'
import colorCodes from 'utils/colorCodes'
import SkinnyCurvyLabel from './SkinnyCurvyLabel'

type Props = {
  product: t.Product
  listName: string
  index: number
  queryID?: string
  onWishlistRemove?: (product: t.Product) => void
  identifier?: string
}

declare global {
  interface Window {
    abbranch: any
    posthog: any
  }
}

const { merchantClickout } = config.apiEndpoints

export default function ProductWidget(props: Props): JSX.Element {
  const [ref, src] = useProductImage(props.product.imageMediumURL, props.product.merchantName)
  const [isDirectBuy, , index] = useGrouped(props.product)
  const wishlist = useWishlistListingItem({ objectId: props.product.objectID })
  const [wrapperRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: `-200px 0px -200px 0px`,
  })
  const wasSeen = React.useRef(false)
  const p = props.product

  const reqParams = new URLSearchParams()

  if (typeof window !== 'undefined') {
    reqParams.set('branch', window.abbranch || 'master')
    if (
      config.features.posthog
      && config.features.posthogDistinctIdClickoutUrlParam
      && window.posthog) {
      const distinctId = window.posthog.get_distinct_id()
      reqParams.set('phdid', distinctId)
    }
  }

  const baseUrl = merchantClickout + '/' + p.objectID
  const queryString = reqParams.toString()

  const directBuyUrlWithParams = `/product/${p.groupedId}/` + (index ? `?index=${index}` : '')
  const clickoutUrlWithParams = queryString ? `${baseUrl}?${queryString}` : baseUrl

  const target = isDirectBuy
    ? directBuyUrlWithParams
    : 'nofollow:' + clickoutUrlWithParams

  const isBestSelling = React.useMemo(() => {
    return props.product._tags?.includes('bestselling')
  }, [props.product])

  React.useEffect(() => {
    if (!inView || wasSeen.current) return
    actions.scrollIntoView(props.product, props.listName, props.index, props.queryID)
    wasSeen.current = true
  }, [inView, props.product, props.listName, props.index])

  const handleWishlistIconClick = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
    actions.heartClick(p, props.listName, props.index, wishlist.selected, props.queryID)
    if (wishlist.selected) {
      wishlist.remove()
      props.onWishlistRemove && props.onWishlistRemove(props.product)
    } else {
      wishlist.add()
    }
  }

  props.product.wunderVariantColors?.map((color) => console.log(color))

  return (
    <Wrapper
      id={props.product.objectID}
      ref={wrapperRef}
      data-cy-ctx="molecules/ProductWidget"
      to={target}
      onClick={() => actions.click(p, props.listName, props.index, props.queryID)}
      className="ProductWidget"
    >
      {adminMode && (
        <AdminInfo
          data-cy-state="admin:object-id"
          data-cy-handle="admin-btn"
          content={props.product.objectID}
        />
      )}
      <div className="image-wrapper" ref={ref}>
        <img src={src} alt={p.productName} data-cy-handle="img-clickout" />
        {config.features.wishlist && (
          <WishlistWrapper
            className="heart"
            added={wishlist.selected || wishlist.isFetching}
            data-cy-state="wishlist"
            data-cy-handle="wishlist-heart"
            onClick={handleWishlistIconClick}
          >
            <FaRegHeart />
            {wishlist.selected && <span className="hide" data-cy-state="wishlist-added" />}
          </WishlistWrapper>
        )}
        {/* {p.isOnSale && (
          <div className="sale-ribbon" data-cy-state="sale:sale-ribbon">
            -{(p.salePercentage * 100).toFixed(0)}%
          </div>
        )} */}
        {p.promoted && (
          <div className="promotion" data-cy-state="promoted">
            ANZEIGE
          </div>
        )}
        {isBestSelling && !p.promoted && (
          <div className="best-selling" data-cy-state="best-selling">
            BESTSELLER
          </div>
        )}
        {adminMode && <SkinnyCurvyLabel data-cy-state="admin:curvy-ai" tags={p._tags || []} />}
      </div>
      <div className="brand-wrapper">
        <div className="brand">{p.productManufacturerBrand}</div>
      </div>
      <div className="label">
        <div className="inner">{p.productName}</div>
      </div>
      <div className="price">
        {/* {p.isOnSale && (
          <span className="strike" data-cy-state="sale:strike-price">
            {formatPrice(p.productPriceOld || '')}€
          </span>
        )} */}
        <span className="actual">{formatPrice(p.productPrice)}€</span>
      </div>
      {props.product.wunderVariantColors && props.product.wunderVariantColors.length > 1 && (
        <div className="color-options" data-cy-state="has-color-options">
          {props.product.wunderVariantColors
            .slice(0, 5)
            .map((color) => (colorCodes[color] ? <Color color={color} /> : null))}
          {props.product.wunderVariantColors.length > 5 && (
            <div className="more-colors" data-cy-state="has-more-colors">
              +
            </div>
          )}
        </div>
      )}
      {isDirectBuy || (
        <button
          className="clickout c-merchant"
          data-cy-state="affiliate"
          data-cy-handle="btn-clickout"
        >
          <FiExternalLink />
          <span>zu {p.merchantName}</span>
        </button>
      )}

      {props.product.disabled && (
        <div
          className="disabled-wrapper"
          data-cy-state="disabled"
          data-cy-handle="disabled-button"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            wishlist.remove()
          }}
        >
          Produkt aktuell nicht verfügbar
          <Button filled>
            <FaTrash /> Löschen
          </Button>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  width: 100%;
  position: relative;
  font-family: Raleway, Helvetica, Arial, sans-serif;
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  > .image-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    > img {
      width: 100%;
      cursor: pointer;
      display: block;
    }

    > .sale-ribbon {
      position: absolute;
      bottom: -70px;
      left: -70px;
      width: 140px;
      height: 140px;
      border-radius: 140px;
      opacity: 0.8;
      background-color: #eeeced;
      padding-left: 85px;
      padding-top: 35px;
      color: #c8402a;
      font-family: 'Open Sans';
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 2.4px;
      line-height: 16px;
    }

    > .promotion,
    > .best-selling {
      position: absolute;
      right: 0;
      bottom: 20px;
      color: #262626;
      font-family: Raleway;
      font-size: 9px;
      letter-spacing: 2px;
      line-height: 16px;
      opacity: 0.8;
      background-color: #f8f7f8;
      padding: 2px 8px;
    }

    > .SkinnyCurvyLabel {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  > .brand-wrapper {
    height: 20px;
    margin-top: 8px;
    position: relative;
    > .brand {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      color: #262626;
      font-family: Raleway;
      font-size: 11px;
      letter-spacing: 2.4px;
      line-height: 20px;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  > .label {
    position: relative;
    height: 20px;
    margin-top: 3px;

    @media (min-width: ${ms.LAPTOP_XL}px) {
      height: 26px;
      margin-top: 6px;
    }

    > .inner {
      position: absolute;
      width: 100%;
      height: 100%;
      color: #262626;
      font-family: 'Cormorant Garamond';
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.51px;
      line-height: 20px;
      @media (min-width: ${ms.LAPTOP_XL}px) {
        font-size: 18px;
        letter-spacing: 0.34px;
        line-height: 26px;
      }
    }
  }

  > .price {
    margin-top: 8px;
    height: 16px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2.58px;
    line-height: 16px;
    @media (min-width: ${ms.TABLET}px) {
      margin-top: 10px;
      font-size: 14px;
      letter-spacing: 3.02px;
    }
    > .strike {
      color: #a7a9ac;
      margin-right: 10px;
      text-decoration: line-through;
    }

    > .actual {
      color: #262626;
    }

    > .actual-strike {
      color: #c8402a;
    }
  }

  > .clickout {
    margin-top: 10px;
    height: auto;
    min-height: 30px;
    text-align: center;
    background: #993452;
    padding: 3px;
    padding-left: 0;
    color: #ffffff;
    display: inline-block;
    border: none;
    width: 100%;
    cursor: pointer;
    font-family: Raleway, Helvetica, Arial, sans-serif;

    @media (min-width: ${ms.TABLET}px) {
      margin-top: 17px;
    }

    > svg {
      margin-right: 10px;
      margin-bottom: -3px;
      font-size: 15px;
    }
    > span {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2.4px;
      line-height: 16px;
      margin-top: 6px;

      @media (min-width: ${ms.TABLET}px) {
        margin-top: 1px;
        font-size: 11px;
        letter-spacing: 2.4px;
        line-height: 25px;
      }
    }
  }

  > .color-options {
    display: flex;
    margin-top: 15px;

    > .more-colors {
      font-size: 20px;
      color: black;
    }
  }

  > .c-merchant {
    margin-top: 2px;
    text-align: left;
    background: none;
    color: #993452;
    text-transform: uppercase;
    display: flex;
    @media (min-width: ${ms.TABLET}px) {
      margin-top: 10px;
    }
    > svg {
      width: 20px;
      margin-top: 3px;
      font-size: 16px;
    }
  }

  > .disabled-wrapper {
    line-height: 1.58;
    z-index: 9;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-weight: bold;
    flex-direction: column;

    > button {
      margin-top: 15px;
    }
  }
`

const WishlistWrapper = styled.div`
  position: absolute;
  right: 13px;
  height: 30px;
  width: 30px;
  top: 13px;
  padding: 7px;
  border-radius: 50px;
  background: ${(props) => (props.added ? '#993452' : 'lightgrey')};
  color: white;
  cursor: pointer;
  > svg {
    width: 16px;
    height: 16px;
    display: block;
  }
`

const Color = styled.div.attrs((p) => ({
  style: {
    background: colorCodes[p.color].bg,
  },
}))`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-right: 10px;
  border: ${(p) => (p.color.toLowerCase() === 'weiß' ? '1px solid grey' : 'none')};
`
