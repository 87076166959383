import { addRule } from 'redux-ruleset'
import * as api from './utils/api'
import * as at from './const'

addRule({
  id: 'posthog/SEND_CAPTURE',
  target: at.SEND_CAPTURE,
  output: '#tracking-event',
  consequence: (action) => {
    api.pushToPosthog(action.payload)
  },
})
