declare global {
  interface Window {
    posthog: any
  }
}

/** @firescoutMockFn posthog.sendCapture */
export const pushToPosthog = (event: Record<string, unknown>) => {
  // console.log(event)
  if (typeof window !== 'undefined') {
    // TODO - implement posthog distinct_id: posthog.get_distinct_id() Get the distinct_id for later use
    window.posthog && window.posthog.capture(
      event.eventName,
      event.eventProperties
    )
  }
}