// @flow

interface ColorCode {
  bg: string
  color: string
  weight: number
}

// Base colors
const baseColors: Record<string, ColorCode> = {
  schwarz: { bg: '#000000', color: 'white', weight: 1 },
  blau: { bg: '#2626d0', color: 'white', weight: 2 },
  weiß: { bg: '#ffffff', color: 'black', weight: 3 },
  rot: { bg: '#f9121c', color: 'white', weight: 4 },
  'beige/creme': { bg: '#E8CD90', color: 'black', weight: 5 },
  'rosa/pink': { bg: '#f05fda', color: 'white', weight: 6 },
  grau: { bg: '#BCBAB8', color: 'black', weight: 7 },
  grün: { bg: '#3b9141', color: 'white', weight: 8 },
  braun: { bg: '#6b3a19', color: 'white', weight: 9 },
  lila: { bg: '#692E95', color: 'white', weight: 10 },
  bunt: {
    bg: 'linear-gradient(to right, orange , yellow, green, cyan, blue, violet)',
    color: 'black',
    weight: 11,
  },
  gelb: { bg: '#fdf12f', color: 'black', weight: 12 },
  silber: { bg: '#777575', color: 'white', weight: 13 },
  gold: { bg: '#d2a91b', color: 'black', weight: 14 },
  orange: { bg: '#eb8123', color: 'white', weight: 15 },
  metallic: { bg: '#8d847e', color: 'white', weight: 16 },
  'gemustert': { bg: 'transparent', color: 'transparent', weight: 17 },
  // 'nude': { bg: 'yellow', color: 'white', weight: 18 },
  // 'sonstige': { bg: 'yellow', color: 'black', weight: 19 },
  // 'transparent': { bg: 'yellow', color: 'black', weight: 12 },
}

const colorHandler = {
  get(target: Record<string, ColorCode>, prop: string): ColorCode | undefined {
    if (prop in target) {
      return target[prop]
    }

    if (prop.startsWith('zweifarbig_') || prop.startsWith('dreifarbig_')) {
      const parts = prop.split('_')
      const colors = parts.slice(1).map(color => 
        color in baseColors ? baseColors[color].bg : 'transparent'
      )

      return {
        bg: `linear-gradient(to right, ${colors.join(' , ')})`,
        color: colors[0] === 'black' ? 'white' : 'black',
        weight: 20
      }
    }

    return undefined
  }
}

const colorCodes = new Proxy(baseColors, colorHandler)

export default colorCodes