import * as at from './const'

export const sendCapture = (event: any) => ({
  type: at.SEND_CAPTURE,
  payload: event,
})

export type SendCapture = ReturnType<typeof sendCapture>

export type Action = SendCapture
