// @flow
import { addRule } from 'redux-ruleset'
import * as ProductModal from 'theme/_core/ProductModal/actions'
import config from 'config'

const { merchantClickout } = config.apiEndpoints

declare global {
  interface Window {
    abbranch: any
    posthog: any
  }
}

addRule({
  id: 'clickout/PRODUCT_MODAL',
  target: ProductModal.CLICKOUT,
  output: '#clickout',
  consequence: (action) => {
    const { objectID } = action.payload
    const reqParams = new URLSearchParams()

    if (typeof window !== 'undefined') {
      reqParams.set('branch', window.abbranch || 'master')
      if (
        config.features.posthog
        && config.features.posthogDistinctIdClickoutUrlParam
        && window.posthog) {
        const distinctId = window.posthog.get_distinct_id()
        reqParams.set('phdid', distinctId)
      }
    }

    const baseUrl = merchantClickout + '/' + objectID
    const queryString = reqParams.toString()
    const urlWithParams = queryString ? `${baseUrl}?${queryString}` : baseUrl

    window.open(urlWithParams, '_blank')
  },
})
